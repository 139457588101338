import React from 'react'
import { graphql } from 'gatsby'
import { bindAll } from 'lodash'

import { Layout, Slider, HoverGroup, HoverItem, Fade } from '../components/index'

class Cinema extends React.Component {

  title = 'Cinéma'

  constructor(props) {
    super(props)

    this.slider = React.createRef()

    bindAll(this, ['onCurrentHover', 'onMouseLeaveMovies', 'handleClick'])

    this.state = {
      current: false
    }
  }

  handleClick(e) {
    if (this.slider.current.state.dragged) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  onCurrentHover(slug) {
    this.setState({current: slug})
  }

  onMouseLeaveMovies() {
    this.setState({current: false})
  }

  render() {
    return (
      <Layout page={this}>
        <div className="cinema">
          <div className="cinema__bg" style={{backgroundImage: `url(${this.props.data.global.cinemaBackground.url}?auto=format&fit=min&w=1200&q=50)`}}></div>

          <div className="cinema__cover-slider">
            {
              this.props.data.movies.edges.map(({node: movie}) => (
                <div key={movie.slug} className={this.state.current === movie.slug ? 'cinema__cover-slide is-active' : 'cinema__cover-slide' }>
                  <div style={{backgroundImage: `url(${movie.backgroundCover.url}?auto=format&fit=min&w=1200&q=50)`}}></div>
                </div>
              ))
            }
          </div>

          <div className="cinema__content wrapper" onMouseLeave={this.onMouseLeaveMovies}>
            <HoverGroup onCurrent={this.onCurrentHover}>
              <Slider className="cinema__slider" ref={this.slider}>
                {
                  this.props.data.movies.edges.map(({node: movie}) => (
                    <div key={movie.slug} className="h-full">
                      <Fade to={`/cinema/${movie.slug}/`} className="refresh">
                        <div className="cinema__slide" onClick={this.handleClick}>
                          <HoverItem id={movie.slug}>
                            <div className="cinema__cover" style={{backgroundImage: `url(${movie.poster.url}?auto=format&fit=min&w=8&blur=8)`}}>
                              <img srcSet={`${movie.poster.url}?auto=format&fit=min&w=600 600w, ${movie.poster.url}?auto=format&fit=min&w=900 900w, ${movie.poster.url}?auto=format&fit=min&w=1200 1200w,`} alt="" className="lazyload" sizes="100vw, (min-width: 768px) 50vw, (min-width: 1024px) 30vw"/>
                            </div>
                            <div className="relative">
                              <div className="cinema__foot">
                                <h2 className="font-main text-12"><span className="dashed">{movie.subtitle}</span></h2>
                                <span className="font-main text-12 opacity-60">{movie.excerpt}</span>
                              </div>
                            </div>
                          </HoverItem>
                        </div>
                      </Fade>
                    </div>
                  ))
                }
              </Slider>
            </HoverGroup>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Cinema

export const query = graphql`
  query MoviesQuery {
    global: datoCmsGlobal {
      cinemaBackground {
        url
      }
    }
    movies: allDatoCmsMovie(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          subtitle
          excerpt
          slug
          backgroundCover {
            url
          }
          poster {
            url
          }
        }
      }
    }
  }
`
